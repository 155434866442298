<template>
	<div id="clinic">
		<v-head currentIndex="1"></v-head>
		<router-view></router-view>
		<v-foot></v-foot>
	</div>
</template>

<script>
	import vHead from '@/layouts/Head.vue'
	import vFoot from '@/layouts/Foot.vue'
	export default {
		components: {
			vHead,
			vFoot
		}
	}
</script>

<style lang="scss" scoped>
   #clinic {
	   width: 100%;
   }
</style>
